export default [
  {
    path: '/wilayah',
    component: () => import('@/views/pages/setting/wilayah/Index.vue'),
    children:[
        {
          path: 'region',
          name: 'region',
          component: () => import('@/views/pages/setting/wilayah/region/Index.vue'),
          meta:{
            navActiveLink: 'region',
            pageActive: 'region',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Region',
                active: true,
              },
            ],
          },
        },
        {
          path: 'region/create',
          name: 'region-create',
          component: () => import('@/views/pages/setting/wilayah/region/Form.vue'),
          meta:{
            navActiveLink: 'region',
            pageActive: 'region',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Region',
                to: '/wilayah/region'
              },
              {
                text: 'Tambah',
                active: true,
              },
            ],
          }
        },
        {
          path: 'region/edit/:id',
          name: 'region-edit',
          component: () => import('@/views/pages/setting/wilayah/region/Form.vue'),
          meta:{
            navActiveLink: 'region',
            pageActive: 'region',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Region',
                to: '/wilayah/region'
              },
              {
                text: 'Edit',
                active: true,
              },
            ],
          }
        },
        {
          path: 'region/detail/:id',
          name: 'region-detail',
          component: () => import('@/views/pages/setting/wilayah/region/Detail.vue'),
          meta:{
            navActiveLink: 'region',
            pageActive: 'region',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Region',
                to: '/wilayah/region'
              },
              {
                text: 'Detail',
                active: true,
              },
            ],
          }
        },
        {
          path: 'area',
          name: 'area',
          component: () => import('@/views/pages/setting/wilayah/area/Index.vue'),
          meta:{
            navActiveLink: 'region',
            pageActive: 'area',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Area Layanan',
                active: true,
              },
            ],
          },
        },
          {
            path: 'area/create',
            name: 'area-create',
            component: () => import('@/views/pages/setting/wilayah/area/Form.vue'),
            meta:{
              navActiveLink: 'region',
              pageActive: 'area',
              pageTitle: 'Setting Wilayah',
              breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Area Layanan',
                to: '/wilayah/area'
              },
              {
                text: 'Tambah',
                active: true,
              },
            ],
            }
          },
          {
            path: 'area/edit/:id',
            name: 'area-edit',
            component: () => import('@/views/pages/setting/wilayah/area/Form.vue'),
            meta:{
              navActiveLink: 'region',
              pageActive: 'area',
              pageTitle: 'Setting Wilayah',
              breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Area Layanan',
                to: '/wilayah/area'
              },
              {
                text: 'Edit',
                active: true,
              },
            ],
            }
          },
          {
          path: 'area/detail/:id',
          name: 'area-detail',
          component: () => import('@/views/pages/setting/wilayah/area/Detail.vue'),
          meta:{
            navActiveLink: 'region',
            pageActive: 'area',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Area Layanan',
                to: '/wilayah/area'
              },
              {
                text: 'Detail',
                active: true,
              },
            ],
          }
        },
        {
          path: 'layanan',
          name: 'layanan',
          component: () => import('@/views/pages/setting/wilayah/layanan/Index.vue'),
          meta:{
            navActiveLink: 'region',
            pageTitle: 'Setting Wilayah',
            pageActive: 'layanan',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Unit Layanan Pelanggan',
                active: true,
              },
            ],
          },
        },
        {
          path: 'layanan/create',
          name: 'layanan-create',
          component: () => import('@/views/pages/setting/wilayah/layanan/Form.vue'),
          meta:{
            navActiveLink: 'layanan',
            pageActive: 'layanan',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Unit Layanan Pelanggan',
                to: '/wilayah/layanan'
              },
              {
                text: 'Tambah',
                active: true,
              },
            ],
          }
        },
        {
          path: 'layanan/edit/:id',
          name: 'layanan-edit',
          component: () => import('@/views/pages/setting/wilayah/layanan/Form.vue'),
          meta:{
            navActiveLink: 'layanan',
            pageActive: 'layanan',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Unit Layanan Pelanggan',
                to: '/wilayah/layanan'
              },
              {
                text: 'Edit',
                active: true,
              },
            ],
          }
        },
        {
          path: 'layanan/detail/:id',
          name: 'layanan-detail',
          component: () => import('@/views/pages/setting/wilayah/layanan/Detail.vue'),
          meta:{
            navActiveLink: 'region',
            pageActive: 'layanan',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Unit Layanan Pelanggan',
                to: '/wilayah/layanan'
              },
              {
                text: 'Detail',
                active: true,
              },
            ],
          }
        },{
          path: 'kantorjaga',
          name: 'kantorjaga',
          component: () => import('@/views/pages/setting/wilayah/kantorjaga/Index.vue'),
          meta:{
            navActiveLink: 'region',
            pageTitle: 'Setting Wilayah',
            pageActive: 'kantorjaga',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Kantor Jaga',
                active: true,
              },
            ],
          },
        },
        {
          path: 'kantorjaga/create',
          name: 'kantorjaga-create',
          component: () => import('@/views/pages/setting/wilayah/kantorjaga/Form.vue'),
          meta:{
            navActiveLink: 'kantorjaga',
            pageActive: 'kantorjaga',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Kantor Jaga',
                to: '/wilayah/kantorjaga'
              },
              {
                text: 'Tambah',
                active: true,
              },
            ],
          }
        },
        {
          path: 'kantorjaga/edit/:id',
          name: 'kantorjaga-edit',
          component: () => import('@/views/pages/setting/wilayah/kantorjaga/Form.vue'),
          meta:{
            navActiveLink: 'kantorjaga',
            pageActive: 'kantorjaga',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Kantor Jaga',
                to: '/wilayah/kantorjaga'
              },
              {
                text: 'Edit',
                active: true,
              },
            ],
          }
        },
        {
          path: 'kantorjaga/detail/:id',
          name: 'kantorjaga-detail',
          component: () => import('@/views/pages/setting/wilayah/kantorjaga/Detail.vue'),
          meta:{
            navActiveLink: 'region',
            pageActive: 'kantorjaga',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Kantor Jaga',
                to: '/wilayah/kantorjaga'
              },
              {
                text: 'Detail',
                active: true,
              },
            ],
          }
        },
        {
          path: '/wilayah-peta',
          name: 'wilayah-peta',
          component: () => import('@/views/pages/setting/wilayah/MapV2.vue'),
          meta:{
            navActiveLink: 'region',
            pageActive: 'wilayah-peta',
            pageTitle: 'Setting Wilayah',
            breadcrumb: [
              {
                text: 'Wilayah',
              },
              {
                text: 'Peta Sebaran',
                active: true,
              },
            ],
          }
        },
        {
          path: '/setting-wilayah',
          component: () => import('@/layouts/full/LayoutFull.vue'),
        }
    ]
  },
  {
    path: '/administrasi',
    component: () => import('@/views/pages/setting/administrasi/Index.vue'),
    children:[
        {
          path: 'jabatan',
          name: 'jabatan',
          component: () => import('@/views/pages/setting/administrasi/jabatan/Index.vue'),
          meta:{
            navActiveLink: 'jabatan',
            pageActive: 'jabatan',
            pageTitle: 'Setting Administrasi',
            breadcrumb: [
              {
                text: 'Administrasi',
              },
              {
                text: 'Jabatan',
                active: true,
              },
            ],
          },
        },
        {
          path: 'jabatan/create',
          name: 'jabatan-create',
          component: () => import('@/views/pages/setting/administrasi/jabatan/Form.vue'),
          meta:{
            navActiveLink: 'jabatan',
            pageActive: 'jabatan',
            pageTitle: 'Setting Administrasi',
            breadcrumb: [
              {
                text: 'Administrasi',
              },
              {
                text: 'Jabatan',
                to: '/administrasi/jabatan'
              },
              {
                text: 'Tambah',
                active: true,
              },
            ],
          }
        },
        {
          path: 'jabatan/edit/:id',
          name: 'jabatan-edit',
          component: () => import('@/views/pages/setting/administrasi/jabatan/Form.vue'),
          meta:{
            navActiveLink: 'jabatan',
            pageActive: 'jabatan',
            pageTitle: 'Setting Administrasi',
            breadcrumb: [
              {
                text: 'Administrasi',
              },
              {
                text: 'Jabatan',
                to: '/administrasi/jabatan'
              },
              {
                text: 'Edit',
                active: true,
              },
            ],
          }
        },
        
        {
          path: 'dokumen',
          name: 'dokumen',
          component: () => import('@/views/pages/setting/administrasi/dokumen/Index.vue'),
          meta:{
            navActiveLink: 'jabatan',
            pageActive: 'dokumen',
            pageTitle: 'Setting Administrasi',
            breadcrumb: [
              {
                text: 'Administrasi',
              },
              {
                text: 'Dokumen',
                active: true,
              },
              
            ],
          }
        },
          {
            path: 'dokumen/create',
            name: 'dokumen-create',
            component: () => import('@/views/pages/setting/administrasi/dokumen/Form.vue'),
            meta:{
              navActiveLink: 'jabatan',
              pageActive: 'dokumen',
              pageTitle: 'Setting Administrasi',
              breadcrumb: [
              {
                text: 'Administrasi',
              },
              {
                text: 'Dokumen',
                to: '/administrasi/dokumen'
              },
              {
                text: 'Tambah',
                active: true,
              },
            ],
            }
          },
          {
            path: 'dokumen/edit/:id',
            name: 'dokumen-edit',
            component: () => import('@/views/pages/setting/administrasi/dokumen/Form.vue'),
            meta:{
              navActiveLink: 'jabatan',
              pageActive: 'dokumen',
              pageTitle: 'Setting Administrasi',
              breadcrumb: [
              {
                text: 'Administrasi',
              },
              {
                text: 'Dokumen',
                to: '/administrasi/dokumen'
              },
              {
                text: 'Edit',
                active: true,
              },
            ],
            }
          },
        {
          path: 'kepemilikan',
          name: 'kepemilikan',
          component: () => import('@/views/pages/setting/administrasi/kepemilikan/Index.vue'),
          meta:{
            navActiveLink: 'jabatan',
            pageTitle: 'Setting Administrasi',
            pageActive: 'kepemilikan',
            breadcrumb: [
              {
                text: 'Administrasi',
              },
              {
                text: 'Kepemilikan',
                active: true,
              },
            ],
          },
        },
        {
          path: 'kepemilikan/create',
          name: 'kepemilikan-create',
          component: () => import('@/views/pages/setting/administrasi/kepemilikan/Form.vue'),
          meta:{
            navActiveLink: 'jabatan',
            pageActive: 'kepemilikan',
            pageTitle: 'Setting Administrasi',
            breadcrumb: [
              {
                text: 'Administrasi',
              },
              {
                text: 'Kepemilikan',
                to: '/administrasi/kepemilikan'
              },
              {
                text: 'Tambah',
                active: true,
              },
            ],
          }
        },
        {
          path: 'kepemilikan/edit/:id',
          name: 'kepemilikan-edit',
          component: () => import('@/views/pages/setting/administrasi/kepemilikan/Form.vue'),
          meta:{
            navActiveLink: 'jabatan',
            pageActive: 'kepemilikan',
            pageTitle: 'Setting Administrasi',
            breadcrumb: [
              {
                text: 'Administrasi',
              },
              {
                text: 'Kepemilikan',
                to: '/administrasi/kepemilikan'
              },
              {
                text: 'Edit',
                active: true,
              },
            ],
          }
        },
    ]
  },

  {
    path: '/contact',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '/',
        name: 'contact',
        component: () => import('@/views/pages/setting/contact/Index.vue'),
      },
      {
        path: '/create',
        name: 'contact-create',
        component: () => import('@/views/pages/setting/contact/Form.vue'),
        meta:{
          navActiveLink: 'contact',
        }
      },
      {
        path: '/edit/:id',
        name: 'contact-edit',
        component: () => import('@/views/pages/setting/contact/Form.vue'),
        meta:{
          navActiveLink: 'contact',
        }
      },
    ]
  },
  {
    path: '/role',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'role',
        component: () => import('@/views/pages/setting/role/Index.vue'),
        meta:{
          pageTitle: 'Role',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Daftar Role',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'role-create',
        component: () => import('@/views/pages/setting/role/Form.vue'),
        meta:{
          navActiveLink: 'role',
          pageTitle: 'Role',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Role',
              to: '../role',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'role-edit',
        component: () => import('@/views/pages/setting/role/Form.vue'),
        meta:{
          navActiveLink: 'role',
          pageTitle: 'Role',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Role',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/jobtitle',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'jobtitle',
        component: () => import('@/views/pages/setting/jobtitle/Index.vue'),
        meta:{
          pageTitle: 'Jabatan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Daftar Jabatan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'jobtitle-create',
        component: () => import('@/views/pages/setting/jobtitle/Form.vue'),
        meta:{
          navActiveLink: 'jobtitle',
          pageTitle: 'Jabatan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Jabatan',
              to: '../jobtitle',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'jobtitle-edit',
        component: () => import('@/views/pages/setting/jobtitle/Form.vue'),
        meta:{
          navActiveLink: 'jobtitle',
          pageTitle: 'Jabatan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Jabatan',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/vendortype',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vendortype',
        component: () => import('@/views/pages/setting/vendortype/Index.vue'),
        meta:{
          pageTitle: 'Tipe Vendor',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Daftar Tipe Vendor',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'vendortype-create',
        component: () => import('@/views/pages/setting/vendortype/Form.vue'),
        meta:{
          navActiveLink: 'vendortype',
          pageTitle: 'Tipe Vendor',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Tipe Vendor',
              to: '../vendortype',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'vendortype-edit',
        component: () => import('@/views/pages/setting/vendortype/Form.vue'),
        meta:{
          navActiveLink: 'vendortype',
          pageTitle: 'Tipe Vendor',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Tipe Vendor',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/data-perusahaan',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'data-perusahaan',
        component: () => import('@/views/pages_vendor/settings/data-perusahaan/Show.vue'),
        meta:{
          navActiveLink: 'data-perusahaan',
          pageTitle: 'Setting',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Daftar Data Perusahaan',
              active: true,
            },
          ],
        }
      },
      {
        path: '/data-peruhasaan/user/show/:id',
        name: 'data-perusahaan-user-view',
        component: () => import('@/views/pages_vendor/settings/data-perusahaan/include/user/Show.vue'),
        meta:{
          navActiveLink: 'data-perusahaan',
          pageTitle: 'Setting',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Data Perusahaan',
              to: '/data-perusahaan',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
      {
        path: '/data-peruhasaan/user/edit/:id',
        name: 'data-perusahaan-user-edit',
        component: () => import('@/views/pages_vendor/settings/data-perusahaan/Form.vue'),
        meta:{
          navActiveLink: 'data-perusahaan',
          pageTitle: 'Setting',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Data Perusahaan',
              to: '/data-perusahaan',
            },
            {
              text: 'Ajukan Perubahan Data',
              active: true,
            },
          ],
        }
      },
      {
        path: '/data-peruhasaan/user/sub-edit/:id',
        name: 'data-perusahaan-user-sub-edit',
        component: () => import('@/views/pages_vendor/settings/data-perusahaan/include/user/Form.vue'),
        meta:{
          navActiveLink: 'data-perusahaan',
          pageTitle: 'Setting',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Data Perusahaan',
              to: './',
            },
            {
              text: 'Edit User',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/user',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'user',
        component: () => import('@/views/pages/setting/user/Index.vue'),
        meta:{
          navActiveLink: 'user',
          pageTitle: 'User Management',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Daftar User',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'user-create',
        component: () => import('@/views/pages/setting/user/Form.vue'),
        meta:{
          navActiveLink: 'user',
          pageTitle: 'User Management',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'User',
              to: '../user',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'user-edit',
        component: () => import('@/views/pages/setting/user/Form.vue'),
        meta:{
          navActiveLink: 'user',
          pageTitle: 'User Management',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'User',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'user-view',
        component: () => import('@/views/pages/setting/user/Show.vue'),
        meta:{
          navActiveLink: 'user',
          pageTitle: 'User Management',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'User',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/vehicle-type',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vehicle-type',
        component: () => import('@/views/pages/setting/vehicle/vehicle_type/Index.vue'),
        meta:{
          navActiveLink: 'vehicle-type',
          pageTitle: 'Tipe Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Daftar Tipe Kendaraan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'vehicle-type-create',
        component: () => import('@/views/pages/setting/vehicle/vehicle_type/Form.vue'),
        meta:{
          navActiveLink: 'vehicle-type',
          pageTitle: 'Tipe Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Tipe Kendaraan',
              to: '../vehicle-type',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'vehicle-type-edit',
        component: () => import('@/views/pages/setting/vehicle/vehicle_type/Form.vue'),
        meta:{
          navActiveLink: 'vehicle-type',
          pageTitle: 'Tipe Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Tipe Kendaraan',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/vehicle-manufacture',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vehicle-manufacture',
        component: () => import('@/views/pages/setting/vehicle/manufacture/Index.vue'),
        meta:{
          navActiveLink: 'vehicle-manufacture',
          pageTitle: 'Pabrikan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Daftar Pabrikan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'vehicle-manufacture-create',
        component: () => import('@/views/pages/setting/vehicle/manufacture/Form.vue'),
        meta:{
          navActiveLink: 'vehicle-manufacture',
          pageTitle: 'Pabrikan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Pabrikan',
              to: '../vehicle-manufacture',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'vehicle-manufacture-edit',
        component: () => import('@/views/pages/setting/vehicle/manufacture/Form.vue'),
        meta:{
          navActiveLink: 'vehicle-manufacture',
          pageTitle: 'Pabrikan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Pabrikan',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/vehicle-body',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vehicle-body',
        component: () => import('@/views/pages/setting/vehicle/vehicle_body/Index.vue'),
        meta:{
          navActiveLink: 'vehicle-body',
          pageTitle: 'Body Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Daftar Body Kendaraan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'vehicle-body-create',
        component: () => import('@/views/pages/setting/vehicle/vehicle_body/Form.vue'),
        meta:{
          navActiveLink: 'vehicle-body',
          pageTitle: 'Body Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Body Kendaraan',
              to: '../vehicle-body',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'vehicle-body-edit',
        component: () => import('@/views/pages/setting/vehicle/vehicle_body/Form.vue'),
        meta:{
          navActiveLink: 'vehicle-body',
          pageTitle: 'Body Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Body Kendaraan',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/vehicle-property',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vehicle-property',
        component: () => import('@/views/pages/setting/vehicle/vehicle_property/Index.vue'),
        meta:{
          pageTitle: 'Kelengkapan Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Daftar Kelengkapan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'vehicle-property-create',
        component: () => import('@/views/pages/setting/vehicle/vehicle_property/Form.vue'),
        meta:{
          navActiveLink: 'vehicle-property',
          pageTitle: 'Kelengkapan Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Kelengkapan',
              to: '../vehicle-property',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'vehicle-property-edit',
        component: () => import('@/views/pages/setting/vehicle/vehicle_property/Form.vue'),
        meta:{
          navActiveLink: 'vehicle-property',
          pageTitle: 'Kelengkapan Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Kelengkapan',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/vehicle-variant',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vehicle-variant',
        component: () => import('@/views/pages/setting/vehicle/vehicle_variant/Index.vue'),
        meta:{
          pageTitle: 'Varian Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Daftar Varian Kendaraan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'vehicle-variant-create',
        component: () => import('@/views/pages/setting/vehicle/vehicle_variant/Form.vue'),
        meta:{
          navActiveLink: 'vehicle-variant',
          pageTitle: 'Varian Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Varian Kendaraan',
              to: '../vehicle-variant',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'vehicle-variant-edit',
        component: () => import('@/views/pages/setting/vehicle/vehicle_variant/Form.vue'),
        meta:{
          navActiveLink: 'vehicle-variant',
          pageTitle: 'Varian Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Varian Kendaraan',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'vehicle-variant-view',
        component: () => import('@/views/pages/setting/vehicle/vehicle_variant/Show.vue'),
        meta:{
          navActiveLink: 'vehicle-variant',
          pageTitle: 'Varian Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kendaraan',
            },
            {
              text: 'Varian Kendaraan',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/operasional-fuel',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'operasional-fuel',
        component: () => import('@/views/pages/setting/operasional/fuel/Index.vue'),
        meta:{
          pageTitle: 'Bahan Bakar Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Daftar Bahan Bakar',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'operasional-fuel-create',
        component: () => import('@/views/pages/setting/operasional/fuel/Form.vue'),
        meta:{
          navActiveLink: 'operasional-fuel',
          pageTitle: 'Bahan Bakar Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Bahan Bakar',
              to: '../operasional-fuel',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'operasional-fuel-edit',
        component: () => import('@/views/pages/setting/operasional/fuel/Form.vue'),
        meta:{
          navActiveLink: 'operasional-fuel',
          pageTitle: 'Bahan Bakar Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Bahan Bakar',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/operasional-tire-size',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'operasional-tire-size',
        component: () => import('@/views/pages/setting/operasional/tire_size/Index.vue'),
        meta:{
          navActiveLink: 'operasional-tire-size',
          pageTitle: 'Ukuran Ban Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Daftar Ukuran Ban Kendaraan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'operasional-tire-size-create',
        component: () => import('@/views/pages/setting/operasional/tire_size/Form.vue'),
        meta:{
          navActiveLink: 'operasional-tire-size',
          pageTitle: 'Ukuran Ban Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Ukuran Ban',
              to: '../operasional-tire-size',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'operasional-tire-size-edit',
        component: () => import('@/views/pages/setting/operasional/tire_size/Form.vue'),
        meta:{
          navActiveLink: 'operasional-tire-size',
          pageTitle: 'Ukuran Ban Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Ukuran Ban',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/operasional-tire-axle',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'operasional-tire-axle',
        component: () => import('@/views/pages/setting/operasional/tire_axle/Index.vue'),
        meta:{
          pageTitle: 'Poros Ban Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Daftar Poros Ban Kendaraan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'operasional-tire-axle-create',
        component: () => import('@/views/pages/setting/operasional/tire_axle/Form.vue'),
        meta:{
          navActiveLink: 'operasional-tire-axle',
          pageTitle: 'Poros Ban Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Poros Ban Kendaraan',
              to: '../operasional-tire-axle',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'operasional-tire-axle-edit',
        component: () => import('@/views/pages/setting/operasional/tire_axle/Form.vue'),
        meta:{
          navActiveLink: 'operasional-tire-axle',
          pageTitle: 'Poros Ban Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Poros Ban Kendaraan',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'operasional-tire-axle-view',
        component: () => import('@/views/pages/setting/operasional/tire_axle/Show.vue'),
        meta:{
          navActiveLink: 'operasional-tire-axle',
          pageTitle: 'Poros Ban Kendaraan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Poros Ban Kendaraan',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/operasional-maintenance-interval',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'operasional-maintenance-interval',
        component: () => import('@/views/pages/setting/operasional/maintenance_interval/Index.vue'),
        meta:{
          pageTitle: 'Interval KM Perawatan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Daftar Interval KM Perawatan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'operasional-maintenance-interval-create',
        component: () => import('@/views/pages/setting/operasional/maintenance_interval/Form.vue'),
        meta:{
          navActiveLink: 'operasional-maintenance-interval',
          pageTitle: 'Interval KM Perawatan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Interval KM Perawatan',
              to: '../operasional-maintenance-interval',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'operasional-maintenance-interval-edit',
        component: () => import('@/views/pages/setting/operasional/maintenance_interval/Form.vue'),
        meta:{
          navActiveLink: 'operasional-maintenance-interval',
          pageTitle: 'Interval KM Perawatan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Interval KM Perawatan',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/operasional-maintenance-item',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'operasional-maintenance-item',
        component: () => import('@/views/pages/setting/operasional/maintenance_item/Index.vue'),
        meta:{
          pageTitle: 'Jenis Perawatan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Daftar Jenis Perawatan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'operasional-maintenance-item-create',
        component: () => import('@/views/pages/setting/operasional/maintenance_item/Form.vue'),
        meta:{
          navActiveLink: 'operasional-maintenance-item',
          pageTitle: 'Jenis Perawatan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Jenis Perawatan',
              to: '../operasional-maintenance-item',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'operasional-maintenance-item-edit',
        component: () => import('@/views/pages/setting/operasional/maintenance_item/Form.vue'),
        meta:{
          navActiveLink: 'operasional-maintenance-item',
          pageTitle: 'Jenis Perawatan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Jenis Perawatan',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/operasional-reminder',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'operasional-reminder',
        component: () => import('@/views/pages/setting/operasional/reminder/Index.vue'),
        meta:{
          navActiveLink: 'operasional-reminder',
          pageTitle: 'Jenis Pengingat',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Daftar Jenis Pengingat',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/operasional-issue-type',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'operasional-issue-type',
        component: () => import('@/views/pages/setting/operasional/isu/Index.vue'),
        meta:{
          navActiveLink: 'operasional-issue-type',
          pageTitle: 'Jenis Isu/Kerusakan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Operasional',
            },
            {
              text: 'Daftar Jenis Isu/Kerusakan',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/skko',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'skko',
        component: () => import('@/views/pages/setting/skko/Index.vue'),
        meta:{
          navActiveLink: 'skko',
          pageTitle: 'SKKO',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Daftar SKKO',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'skko-create',
        component: () => import('@/views/pages/setting/skko/Form.vue'),
        meta:{
          navActiveLink: 'skko',
          pageTitle: 'SKKO',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'SKKO',
              to: '../skko',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'skko-edit',
        component: () => import('@/views/pages/setting/skko/Form.vue'),
        meta:{
          navActiveLink: 'skko',
          pageTitle: 'SKKO',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'SKKO',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'skko-view',
        component: () => import('@/views/pages/setting/skko/Show.vue'),
        meta:{
          navActiveLink: 'skko',
          pageTitle: 'SKKO',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'SKKO',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/contract',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'contract',
        component: () => import('@/views/pages/setting/contract/Index.vue'),
        meta:{
          navActiveLink: 'contract',
          pageTitle: 'Kontrak',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Daftar Kontrak',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'contract-create',
        component: () => import('@/views/pages/setting/contract/Form.vue'),
        meta:{
          navActiveLink: 'contract',
          pageTitle: 'Kontrak',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kontrak',
              to: '../contract',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'contract-edit',
        component: () => import('@/views/pages/setting/contract/Form.vue'),
        meta:{
          navActiveLink: 'contract',
          pageTitle: 'Kontrak',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kontrak',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'contract-view',
        component: () => import('@/views/pages/setting/contract/Show.vue'),
        meta:{
          navActiveLink: 'contract',
          pageTitle: 'Kontrak',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Kontrak',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/project',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'project',
        component: () => import('@/views/pages/setting/project/Index.vue'),
        meta:{
          navActiveLink: 'project',
          pageTitle: 'Project',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Daftar Project',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'project-create',
        component: () => import('@/views/pages/setting/project/Form.vue'),
        meta:{
          navActiveLink: 'project',
          pageTitle: 'Project',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Project',
              to: '../project',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'project-edit',
        component: () => import('@/views/pages/setting/project/Form.vue'),
        meta:{
          navActiveLink: 'project',
          pageTitle: 'Project',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Project',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'project-view',
        component: () => import('@/views/pages/setting/project/Show.vue'),
        meta:{
          navActiveLink: 'project',
          pageTitle: 'Project',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Project',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/application',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'application',
        component: () => import('@/views/pages/setting/application/Index.vue'),
        meta:{
          navActiveLink: 'application',
          pageTitle: 'Application',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Setting Application',
              active: true,
            },
          ],
        }
      },
    ]
  },
 
]
