import store from '@/store'
export default [
  {
    path: '/vehicle',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vehicles',
        component: () => import('@/views/pages/vehicle/vehicles/Index.vue'),
        meta: {
          pageTitle: 'Kendaraan',
          breadcrumb: [
            {
              text: 'Kendaraan',
            },
            {
              text: 'Semua Kendaraan',
              active: true,
            },
          ],
        },
      },
      {
        path: 'create',
        name: 'vehicles-create',
        component: () => import('@/views/pages/vehicle/vehicles/Form.vue'),
        meta:{
          navActiveLink: 'vehicles',
        }
      },
      {
        path: 'edit/:id',
        name: 'vehicles-edit',
        component: () => import('@/views/pages/vehicle/vehicles/Form.vue'),
        meta:{
          navActiveLink: 'vehicles',
        }
      },
      {
        path: 'show/:id',
        name: 'vehicles-view',
        component: () => import('@/views/pages/vehicle/vehicles/Show.vue'),
        meta:{
          user: store.state.profile.info,
          navActiveLink: 'vehicles',
          pageTitle: 'Kendaraan',
          breadcrumb: [
            {
              text: 'Kendaraan',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id/cost',
        name: 'vehicles-cost',
        component: () => import('@/views/pages/vehicle/vehicles/include/maintenance/Cost.vue'),
        meta:{
          navActiveLink: 'vehicles',
          pageTitle: 'Kendaraan',
          breadcrumb: [
            {
              text: 'Kendaraan',
              to: '../../',
            },
            {
              text: 'Biaya',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/vehicle-chart',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vehicle-chart',
        component: () => import('@/views/pages/vehicle/sebaran/Index.vue'),
        meta: {
          pageTitle: 'Sebaran Kendaraan',
          breadcrumb: [
            {
              text: 'Sebaran',
            },
            {
              text: 'Chart',
              active: true,
            },
          ],
        },
      },
    ]
  },
  {
    path: '/vehicle-report',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vehicle-report',
        component: () => import('@/views/pages/vehicle/report/Index.vue'),
        meta: {
          pageTitle: 'Laporan Kendaraan',
          breadcrumb: [
            {
              text: 'Kendaraan',
            },
            {
              text: 'Laporan',
              active: true,
            },
          ],
        },
      },
    ]
  },
]