export default [
  {
    path: '/contactall',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'contactall',
        component: () => import('@/views/pages/contact/contactall/Index.vue'),
        meta:{
          pageTitle: 'Kontak',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Daftar Kontak',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'contactall-create',
        component: () => import('@/views/pages/contact/contactall/Form.vue'),
        meta:{
          navActiveLink: 'contactall',
        }
      },
      {
        path: 'edit/:id',
        name: 'contactall-edit',
        component: () => import('@/views/pages/contact/contactall/Form.vue'),
        meta:{
          navActiveLink: 'contactall',
        }
      },
    ]
  },
  {
    path: '/staff',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'staff',
        component: () => import('@/views/pages/contact/staff/Index.vue'),
        meta:{
          pageTitle: 'Pegawai',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Daftar Pegawai',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'staff-create',
        component: () => import('@/views/pages/contact/staff/Form.vue'),
        meta:{
          navActiveLink: 'staff',
          pageTitle: 'Pegawai',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Pegawai',
              to: '../staff',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'staff-edit',
        component: () => import('@/views/pages/contact/staff/Form.vue'),
        meta:{
          navActiveLink: 'staff',
          pageTitle: 'Pegawai',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Pegawai',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'staff-view',
        component: () => import('@/views/pages/contact/staff/Show.vue'),
        meta:{
          navActiveLink: 'staff',
          pageTitle: 'Pegawai',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Pegawai',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/vendor',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vendor',
        component: () => import('@/views/pages/contact/vendor/Index.vue'),
        meta:{
          pageTitle: 'Vendor',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Daftar Vendor',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'vendor-create',
        component: () => import('@/views/pages/contact/vendor/Form.vue'),
        meta:{
          navActiveLink: 'vendor',
          pageTitle: 'Vendor',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Vendor',
              to: '../vendor',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'vendor-edit',
        component: () => import('@/views/pages/contact/vendor/Form.vue'),
        meta:{
          navActiveLink: 'vendor',
          pageTitle: 'Vendor',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Vendor',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'vendor-view',
        component: () => import('@/views/pages/contact/vendor/Show.vue'),
        meta:{
          navActiveLink: 'vendor',
          pageTitle: 'Vendor',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Vendor',
              to: '../',
            },
            {
              text: 'View',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/workshop',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'workshop',
        component: () => import('@/views/pages/contact/workshop/Index.vue'),
        meta:{
          pageTitle: 'Bengkel',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Daftar Bengkel',
              active: true,
            },
          ],
        }
      },
      {
        path: 'map',
        name: 'workshop-map',
        component: () => import('@/views/pages/contact/workshop/Map.vue'),
        meta:{
          navActiveLink: 'workshop',
          pageTitle: 'Bengkel',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Bengkel',
              to: '../workshop',
            },
            {
              text: 'Peta Sebaran',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'workshop-create',
        component: () => import('@/views/pages/contact/workshop/Form.vue'),
        meta:{
          navActiveLink: 'workshop',
          pageTitle: 'Bengkel',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Bengkel',
              to: '../workshop',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'workshop-edit',
        component: () => import('@/views/pages/contact/workshop/Form.vue'),
        meta:{
          navActiveLink: 'workshop',
          pageTitle: 'Bengkel',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Bengkel',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'workshop-view',
        component: () => import('@/views/pages/contact/workshop/Show.vue'),
        meta:{
          navActiveLink: 'workshop',
          pageTitle: 'Bengkel',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Bengkel',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/supplier',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'supplier',
        component: () => import('@/views/pages/contact/supplier/Index.vue'),
        meta:{
          pageTitle: 'Supplier',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Daftar Supplier',
              active: true,
            },
          ],
        }
      },
      {
        path: 'map',
        name: 'supplier-map',
        component: () => import('@/views/pages/contact/supplier/Map.vue'),
        meta:{
          navActiveLink: 'supplier',
          pageTitle: 'Supplier',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Supplier',
              to: '../supplier',
            },
            {
              text: 'Peta Sebaran',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'supplier-create',
        component: () => import('@/views/pages/contact/supplier/Form.vue'),
        meta:{
          navActiveLink: 'supplier',
          pageTitle: 'Supplier',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Supplier',
              to: '../supplier',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'supplier-edit',
        component: () => import('@/views/pages/contact/supplier/Form.vue'),
        meta:{
          navActiveLink: 'supplier',
          pageTitle: 'Supplier',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Supplier',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'supplier-view',
        component: () => import('@/views/pages/contact/supplier/Show.vue'),
        meta:{
          navActiveLink: 'supplier',
          pageTitle: 'Supplier',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Supplier',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/assurance',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'assurance',
        component: () => import('@/views/pages/contact/assurance/Index.vue'),
        meta:{
          pageTitle: 'Asuransi',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Daftar Asuransi',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'assurance-create',
        component: () => import('@/views/pages/contact/assurance/Form.vue'),
        meta:{
          navActiveLink: 'assurance',
          pageTitle: 'Asuransi',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Asuransi',
              to: '../assurance',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'assurance-edit',
        component: () => import('@/views/pages/contact/assurance/Form.vue'),
        meta:{
          navActiveLink: 'assurance',
          pageTitle: 'Asuransi',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Asuransi',
              to: '../',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'assurance-view',
        component: () => import('@/views/pages/contact/assurance/Show.vue'),
        meta:{
          navActiveLink: 'assurance',
          pageTitle: 'Asuransi',
          breadcrumb: [
            {
              text: 'Kontak',
            },
            {
              text: 'Asuransi',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
]