export default {
  namespaced: false,
  state: {
    connection: null
  },
  getters: {},
  mutations: {
    CONNECTION(state, val) {
      console.log("Connected", val)
      state.connection = val
    },
  },
  actions: {
    sendMessage({ state }, val) {
      if (state.connection == null) {
        console.log("disconnected!")
        return;
      }
      state.connection.send(val.toString())
    },
  },
}
