import checklist from './operational/checklist'

export default [
  ...checklist,
  {
    path: '/operasional/maintenance',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children: [
      {
        path: '',
        name: 'maintenance',
        component: () => import('@/views/pages/operasional/maintenance/Index.vue'),
        meta: {
          pageTitle: 'Perawatan Kendaraan',
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'Perawatan Kendaraan',
              active: true,
            },
          ],
        },
      },
      {
        path: 'create',
        name: 'maintenance-create',
        component: () => import('@/views/pages/operasional/maintenance/Form.vue'),
        meta: {
          navActiveLink: 'maintenance',
          pageTitle: 'Perawatan Kendaraan',
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'Perawatan Kendaraan',
              to: '../maintenance'
            },
            {
              text: 'Add',
              active: true
            }
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'maintenance-edit',
        component: () => import('@/views/pages/operasional/maintenance/Form.vue'),
        meta: {
          navActiveLink: 'maintenance',
          pageTitle: 'Perawatan Kendaraan',
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'Perawatan Kendaraan',
              to: '../maintenance'
            },
            {
              text: 'Edit',
              active: true
            }
          ],
        }
      },
    ]
  },
  {
    path: "/operational/refueling",
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children: [
      {
        path: '',
        name: 'refueling',
        component: () => import('@/views/pages/operasional/refueling/Index.vue'),
        meta: {
          pageTitle: 'BBM',
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'BBM',
              active: true,
            },
          ],
        },
      },
      {
        path: 'create',
        name: 'refueling-create',
        component: () => import('@/views/pages/operasional/refueling/Form.vue'),
        meta: {
          navActiveLink: 'refueling',
          pageTitle: 'BBM',
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'BBM',
              to: '../refueling'
            },
            {
              text: 'Add',
              active: true
            }
          ],
        }
      },
      {
        path: 'detail/:id',
        name: 'refueling-view',
        component: () => import('@/views/pages/operasional/refueling/Detail.vue'),
        meta: {
          navActiveLink: 'refueling',
          pageTitle: 'BBM',
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'BBM',
              to: '../'
            },
            {
              text: 'Detail',
              active: true
            }
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'refueling-edit',
        component: () => import('@/views/pages/operasional/refueling/Form.vue'),
        meta: {
          navActiveLink: 'refueling',
          pageTitle: 'BBM',
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'BBM',
              to: '../'
            },
            {
              text: 'Edit',
              active: true
            }
          ],
        }
      },
    ]
  },
  {
    path: '/operasional/mutation',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children: [
      {
        path: '',
        name: 'mutation',
        component: () => import('@/views/pages/operasional/mutation/mutation_request/Index.vue'),
        meta: {
          pageTitle: 'Permintaan Mutasi',
          breadcrumb: [
            {
              text: 'Mutasi',
            },
            {
              text: 'Permintaan Mutasi',
              active: true,
            }
          ]
        }
      },
      {
        path: 'create',
        name: 'mutation-create',
        component: () => import('@/views/pages/operasional/mutation/mutation_request/Form.vue'),
        meta: {
          navActiveLink: 'mutation',
          pageTitle: 'Permintaan Mutasi',
          breadcrumb: [
            {
              text: 'Mutasi',
            },
            {
              text: 'Permintaan Mutasi',
              to: '../mutation'
            },
            {
              text: 'Tambah',
              active: true
            }
          ]
        }
      },
      {
        path: 'edit/:id',
        name: 'mutation-edit',
        component: () => import('@/views/pages/operasional/mutation/mutation_request/Form.vue'),
        meta: {
          navActiveLink: 'mutation',
          pageTitle: 'Permintaan Mutasi',
          breadcrumb: [
            {
              text: 'Mutasi',
            },
            {
              text: 'Permintaan Mutasi',
              to: '../'
            },
            {
              text: 'Edit',
              active: true
            }
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'mutation-view',
        component: () => import('@/views/pages/operasional/mutation/mutation_request/Show.vue'),
        meta: {
          navActiveLink: 'mutation',
          pageTitle: 'Permintaan Mutasi',
          breadcrumb: [
            {
              text: 'Mutasi',
            },
            {
              text: 'Permintaan Mutasi',
              to: '../'
            },
            {
              text: 'Detail',
              active: true
            }
          ],
        }
      },
    ]
  },
  {
    path: '/operasional/mutation-out',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children: [
      {
        path: '',
        name: 'mutation-out',
        component: () => import('@/views/pages/operasional/mutation/mutation_out/Index.vue'),
        meta: {
          pageTitle: 'Mutasi Keluar',
          breadcrumb: [
            {
              text: 'Mutasi',
            },
            {
              text: 'Mutasi Keluar',
              active: true,
            }
          ]
        }
      },
      // {
      //   path: 'create',
      //   name: 'mutation-out-create',
      //   component: () => import('@/views/pages/operasional/mutation_out/Form.vue'),
      //   meta:{
      //     navActiveLink: 'mutation-out',
      //     pageTitle: 'Mutasi Keluar',
      //     breadcrumb: [
      //       {
      //         text: 'Mutasi',
      //       },
      //       {
      //         text: 'Mutasi Keluar',
      //         to: '../mutation-out'
      //       },
      //       {
      //         text: 'Tambah',
      //         active: true
      //       }
      //     ]
      //   }
      // },
      // {
      //   path: 'edit/:id',
      //   name: 'mutation-edit',
      //   component: () => import('@/views/pages/operasional/mutation_request/Form.vue'),
      //   meta:{
      //     navActiveLink: 'mutation',
      //     pageTitle: 'Permintaan Mutasi',
      //     breadcrumb: [
      //       {
      //         text: 'Mutasi',
      //       },
      //       {
      //         text: 'Permintaan Mutasi',
      //         to: '../'
      //       },
      //       {
      //         text: 'Edit',
      //         active: true
      //       }
      //     ],
      //   }
      // },
      {
        path: 'show/:id',
        name: 'mutation-out-view',
        component: () => import('@/views/pages/operasional/mutation/mutation_request/Show.vue'),
        meta: {
          navActiveLink: 'mutation-out',
          pageTitle: 'Mutasi Keluar',
          breadcrumb: [
            {
              text: 'Mutasi',
            },
            {
              text: 'Mutasi Keluar',
              to: '../'
            },
            {
              text: 'Detail',
              active: true
            }
          ],
        }
      },
    ]
  },
  {
    path: '/operasional/mutation-in',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children: [
      {
        path: '',
        name: 'mutation-in',
        component: () => import('@/views/pages/operasional/mutation/mutation_in/Index.vue'),
        meta: {
          pageTitle: 'Mutasi Masuk',
          breadcrumb: [
            {
              text: 'Mutasi',
            },
            {
              text: 'Mutasi Masuk',
              active: true,
            }
          ]
        }
      },
      // {
      //   path: 'create',
      //   name: 'mutation-out-create',
      //   component: () => import('@/views/pages/operasional/mutation_out/Form.vue'),
      //   meta:{
      //     navActiveLink: 'mutation-out',
      //     pageTitle: 'Mutasi Keluar',
      //     breadcrumb: [
      //       {
      //         text: 'Mutasi',
      //       },
      //       {
      //         text: 'Mutasi Keluar',
      //         to: '../mutation-out'
      //       },
      //       {
      //         text: 'Tambah',
      //         active: true
      //       }
      //     ]
      //   }
      // },
      // {
      //   path: 'edit/:id',
      //   name: 'mutation-edit',
      //   component: () => import('@/views/pages/operasional/mutation_request/Form.vue'),
      //   meta:{
      //     navActiveLink: 'mutation',
      //     pageTitle: 'Permintaan Mutasi',
      //     breadcrumb: [
      //       {
      //         text: 'Mutasi',
      //       },
      //       {
      //         text: 'Permintaan Mutasi',
      //         to: '../'
      //       },
      //       {
      //         text: 'Edit',
      //         active: true
      //       }
      //     ],
      //   }
      // },
      {
        path: 'show/:id',
        name: 'mutation-in-view',
        component: () => import('@/views/pages/operasional/mutation/mutation_request/Show.vue'),
        meta: {
          navActiveLink: 'mutation-in',
          pageTitle: 'Mutasi Masuk',
          breadcrumb: [
            {
              text: 'Mutasi',
            },
            {
              text: 'Mutasi Masuk',
              to: '../'
            },
            {
              text: 'Detail',
              active: true
            }
          ],
        }
      },
    ]
  },
  {
    path: '/operasional/tax',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children: [
      {
        path: '',
        name: 'tax',
        component: () => import('@/views/pages/operasional/taxes/Index.vue'),
        meta: {
          pageTitle: 'Pajak & Kir',
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'Daftar Pajak & Kir',
              active: true,
            }
          ]
        }
      },
    ]
  },
  {
    path: '/vehicle-change',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children: [
      {
        path: '',
        name: 'vehicle-change',
        component: () => import('@/views/pages/vehicle/change_unit/Index.vue'),
        meta: {
          titleIndex: 'Daftar Penggantian Unit',
          pageTitle: 'Penggantian Unit',
          breadcrumb: [
            {
              text: 'Daftar Penggantian Unit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'vehicle-change-create',
        component: () => import('@/views/pages/vehicle/change_unit/Form.vue'),
        meta: {
          navActiveLink: 'vehicle-change',
          pageTitle: 'Penggantian Unit',
          breadcrumb: [
            {
              text: 'Penggantian Unit',
              to: '../vehicle-change',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'vehicle-change-view',
        component: () => import('@/views/pages/vehicle/change_unit/Show.vue'),
        meta: {
          navActiveLink: 'vehicle-change',
          pageTitle: 'Penggantian Unit Detail',
          breadcrumb: [
            {
              text: 'Penggantian Unit',
              to: '..',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'vehicle-change-edit',
        component: () => import('@/views/pages/vehicle/change_unit/Form.vue'),
        meta: {
          navActiveLink: 'vehicle-change',
          pageTitle: 'Penggantian Unit',
          breadcrumb: [
            {
              text: 'Penggantian Unit',
              to: '..',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/operasional/monitor-maintenance',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children: [
      {
        path: '',
        name: 'monitor-maintenance',
        component: () => import('@/views/pages/operasional/monitor_maintenance/Index.vue'),
        meta: {
          titleIndex: 'Monitor Perawatan MT',
          pageTitle: 'Monitor Perawatan',
          breadcrumb: [
            {
              text: 'Daftar Perawatan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'monitor-maintenance-view',
        component: () => import('@/views/pages/maintanance/perawatan/Detail.vue'),
        meta: {
          navActiveLink: 'monitor-maintenance',
          pageTitle: 'Monitor Perawatan',
          breadcrumb: [
            {
              text: 'Daftar Perawatan',
              to: '..',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
]