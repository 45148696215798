import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import * as VueGoogleMaps from 'vue2-google-maps'
import router from './router'
import store from './store'
import App from './App.vue'
import VueSocketIO from 'vue-socket.io'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// const SocketIO = new VueSocketIO({
//   debug: true,
//   connection: 'wss://dev.haleyora.solog.id:443',
//   vuex: {
//     store,
//     actionPrefix: 'SOCKET_',
//     mutationPrefix: 'SOCKET_'
//   },
//   options: {
//     path: "/socket.io/",
//     transports: ["websocket"],
//   } //Optional options
// })

// Vue.use(SocketIO)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

/* eslint-disable */
// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

//map
import 'leaflet/dist/leaflet.css'
import { Icon } from "leaflet"

// numeral
import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'

Vue.filter('numFormat', numFormat(numeral))

// moment
import moment from 'moment'
Vue.use(require('vue-moment'));
Vue.use(require('moment'));

Vue.filter('moment_full', function (value) {
  return moment(value).format('DD/MM/YYYY hh:mm:ss');
})
// Vue.filter('moment', function (value) {
//   return moment(value).format('D MMM YYYY');
// })

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
})

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCo_7YS4SmXO6RD7TKxQCWpRazFVM3SF8A',
    libraries: 'places',
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
