export default {
    namespaced: true,
    state: {
        data: [],
        dataSewa: [],
        dataMaintenance: [],
        dataBappBast: [],
    },
    getters: {},
    mutations: {
        UPDATE(state, data) {
            state.data = data
        },
        READALL(state, data) {
            state.data = []
        },
        READ(state, id) {
            var data = state.data

            var index = data.findIndex(element => {
                console.log(id, element.id)
                return element.id == id
            })
            console.log(index)
            if (index > -1)
                data.splice(index, 1)
            state.data = data
        },
        UPDATESEWA(state, data) {
            state.dataSewa = data
        },
        UPDATEMAINTENANCE(state, data) {
            state.dataMaintenance = data
        },
        UPDATEBAPPBAST(state, data) {
            state.dataBappBast = data
        },
    },
    actions: {},
}
