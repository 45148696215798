export default [
    {
        path: "/operational/checklist",
        component: () => import('@/layouts/full/LayoutFull.vue'),
        children: [
            {
                path: '',
                name: 'checklist',
                component: () => import('@/views/pages/operasional/checklist/Index.vue'),
                meta: {
                    pageTitle: 'Checklist',
                    breadcrumb: [
                        {
                            text: 'Operasional',
                        },
                        {
                            text: 'Checklist',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'create',
                name: 'checklist-create',
                component: () => import('@/views/pages/operasional/checklist/Form.vue'),
                meta: {
                    navActiveLink: 'checklist',
                    pageTitle: 'Checklist',
                    breadcrumb: [
                        {
                            text: 'Operasional',
                        },
                        {
                            text: 'Checklist',
                            to: '../checklist'
                        },
                        {
                            text: 'Add',
                            active: true
                        }
                    ],
                }
            },
            {
                path: 'detail/:id',
                name: 'checklist-view',
                component: () => import('@/views/pages/operasional/checklist/Detail.vue'),
                meta: {
                    navActiveLink: 'checklist',
                    pageTitle: 'Checklist',
                    breadcrumb: [
                        {
                            text: 'Operasional',
                        },
                        {
                            text: 'Checklist',
                            to: '../'
                        },
                        {
                            text: 'Detail',
                            active: true
                        }
                    ],
                }
            },
            {
                path: 'edit/:id',
                name: 'checklist-edit',
                component: () => import('@/views/pages/operasional/checklist/Form.vue'),
                meta: {
                    navActiveLink: 'checklist',
                    pageTitle: 'Checklist',
                    breadcrumb: [
                        {
                            text: 'Operasional',
                        },
                        {
                            text: 'Checklist',
                            to: '../'
                        },
                        {
                            text: 'Edit',
                            active: true
                        }
                    ],
                }
            },
        ]
    }
]