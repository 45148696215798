export default [
    {
        path: '/semua-perawatan',
        component: () => import('@/layouts/full/LayoutFull.vue'),
        children:[
            {
                path: '',
                name: 'semua-perawatan',
                component: () => import('@/views/pages/maintanance/semua-perawatan/Index.vue'),
                meta: {
                    pageTitle: 'Daftar Perawatan',
                    pageActive: 'semua-perawatan',
                    breadcrumb: [
                        {
                            text: 'Perawatan',
                        },
                        {
                            text: 'Daftar Perawatan',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                name: 'semua-perawatan-detail',
                component: () => import('@/views/pages/maintanance/semua-perawatan/Detail.vue'),
                meta:{
                    navActiveLink: 'semua-perawatan',
                    pageActive: 'semua-perawatan',
                    pageTitle: 'Detail Perawatan',
                    breadcrumb: [
                        {
                            text: 'Daftar Perawatan',
                            to: '/semua-perawatan',
                        },
                        {
                            text: 'Detail',
                            active: true,
                        },
                    ],
                }
            },
        ]
    },
    {
        path: '/issue-kendaraan',
        component: () => import('@/layouts/full/LayoutFull.vue'),
        children:[
            {
                path: '',
                name: 'issue-kendaraan',
                component: () => import('@/views/pages/maintanance/issue-kendaraan/Index.vue'),
                meta: {
                    pageTitle: 'Perawatan Non Rutin',
                    pageActive: 'issue-kendaraan',
                    breadcrumb: [
                        {
                            text: 'Perawatan',
                        },
                        {
                            text: 'Daftar Perawatan Non Rutin',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'create',
                name: 'issue-kendaraan-create',
                component: () => import('@/views/pages/maintanance/issue-kendaraan/Form.vue'),
                meta:{
                    navActiveLink: 'issue-kendaraan',
                    pageActive: 'issue-kendaraan',
                    pageTitle: 'Perawatan Non Rutin',
                    breadcrumb: [
                        {
                            text: 'Perawatan',
                            to: '/issue-kendaraan',
                        },
                        {
                            text: 'Perawatan Non Rutin',
                            to: '/issue-kendaraan',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                }
            },
            {
                path: 'edit/:id',
                name: 'issue-kendaraan-edit',
                component: () => import('@/views/pages/maintanance/issue-kendaraan/Form.vue'),
                meta:{
                    navActiveLink: 'issue-kendaraan',
                    pageActive: 'issue-kendaraan',
                    pageTitle: 'Perawatan Non Rutin',
                    breadcrumb: [
                        {
                            text: 'Perawatan',
                            to: '/issue-kendaraan',
                        },
                        {
                            text: 'Perawatan Non Rutin',
                            to: '/issue-kendaraan',
                        },
                        {
                            text: 'Edit',
                            active: true,
                        },
                    ],
                }
            },
            {
                path: 'detail/:id',
                name: 'issue-kendaraan-detail',
                component: () => import('@/views/pages/maintanance/issue-kendaraan/Detail.vue'),
                meta:{
                    navActiveLink: 'issue-kendaraan',
                    pageActive: 'issue-kendaraan',
                    pageTitle: 'Perawatan Non Rutin',
                    breadcrumb: [
                        {
                            text: 'Perawatan Non Rutin',
                            to: '/issue-kendaraan',
                        },
                        {
                            text: 'Detail',
                            active: true,
                        },
                    ],
                }
            },
        ]
    },
    {
        path: '/perawatan',
        component: () => import('@/layouts/full/LayoutFull.vue'),
        children:[
            {
                path: '',
                name: 'perawatan',
                component: () => import('@/views/pages/maintanance/perawatan/Index.vue'),
                meta: {
                    pageTitle: 'Daftar Perawatan',
                    pageActive: 'perawatan',
                    breadcrumb: [
                        {
                            text: 'Perawatan',
                        },
                        {
                            text: 'Daftar Perawatan',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'create',
                name: 'perawatan-create',
                component: () => import('@/views/pages/maintanance/perawatan/Form.vue'),
                meta:{
                    navActiveLink: 'perawatan',
                    pageActive: 'perawatan',
                    pageTitle: 'Tambah Perawatan',
                    breadcrumb: [
                        {
                            text: 'Perawatan',
                            to: '/perawatan',
                        },
                        {
                            text: 'Daftar Perawatan',
                            to: '/perawatan',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                }
            },
            {
                path: 'edit/:id',
                name: 'perawatan-edit',
                component: () => import('@/views/pages/maintanance/perawatan/Form.vue'),
                meta:{
                    navActiveLink: 'perawatan',
                    pageActive: 'perawatan',
                    pageTitle: 'Edit Perawatan',
                    breadcrumb: [
                        {
                            text: 'Perawatan',
                            to: '/perawatan',
                        },
                        {
                            text: 'Daftar Perawatan',
                            to: '/perawatan',
                        },
                        {
                            text: 'Edit',
                            active: true,
                        },
                    ],
                }
            },
            {
                path: 'detail/:id',
                name: 'perawatan-detail',
                component: () => import('@/views/pages/maintanance/perawatan/Detail.vue'),
                meta:{
                    navActiveLink: 'perawatan',
                    pageActive: 'perawatan',
                    pageTitle: 'Detail Perawatan',
                    breadcrumb: [
                        {
                            text: 'Daftar Perawatan',
                            to: '/perawatan',
                        },
                        {
                            text: 'Detail',
                            active: true,
                        },
                    ],
                }
            },
        ]
    },
    {
        path: '/rencana-perawatan',
        component: () => import('@/layouts/full/LayoutFull.vue'),
        children:[
            {
                path: '',
                name: 'rencana-perawatan',
                component: () => import('@/views/pages/maintanance/plan/Index.vue'),
                meta: {
                    pageTitle: 'Daftar Rencana Perawatan',
                    pageActive: 'rencana-perawatan',
                    breadcrumb: [
                        {
                            text: 'Perawatan',
                        },
                        {
                            text: 'Daftar Rencana Perawatan',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'create',
                name: 'rencana-perawatan-create',
                component: () => import('@/views/pages/maintanance/plan/Form.vue'),
                meta:{
                    navActiveLink: 'rencana-perawatan',
                    pageActive: 'rencana-perawatan',
                    pageTitle: 'Tambah Rencana Perawatan',
                    breadcrumb: [
                        {
                            text: 'Perawatan',
                            to: '/rencana-perawatan',
                        },
                        {
                            text: 'Daftar Rencana Perawatan',
                            to: '/rencana-perawatan',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                }
            },
            {
                path: 'edit/:id',
                name: 'rencana-perawatan-edit',
                component: () => import('@/views/pages/maintanance/plan/Form.vue'),
                meta:{
                    navActiveLink: 'rencana-perawatan',
                    pageActive: 'rencana-perawatan',
                    pageTitle: 'Edit Rencana Perawatan',
                    breadcrumb: [
                        {
                            text: 'Perawatan',
                            to: '/rencana-perawatan',
                        },
                        {
                            text: 'Daftar Rencana Perawatan',
                            to: '/rencana-perawatan',
                        },
                        {
                            text: 'Edit',
                            active: true,
                        },
                    ],
                }
            },
            {
                path: 'detail/:id',
                name: 'rencana-perawatan-detail',
                component: () => import('@/views/pages/maintanance/plan/Detail.vue'),
                meta:{
                    navActiveLink: 'rencana-perawatan',
                    pageActive: 'rencana-perawatan',
                    pageTitle: 'Detail Rencana Perawatan',
                    breadcrumb: [
                        {
                            text: 'Daftar Rencana Perawatan',
                            to: '/rencana-perawatan',
                        },
                        {
                            text: 'Detail',
                            active: true,
                        },
                    ],
                }
            },
        ]
    },
    {
        path: '/reminder',
        component: () => import('@/layouts/full/LayoutFull.vue'),
        children:[
            {
                path: '',
                name: 'reminder',
                component: () => import('@/views/pages/maintanance/notifikasi/Index.vue'),
                meta: {
                    pageTitle: 'Daftar Notifikasi & Pengingat',
                    pageActive: 'reminder',
                    breadcrumb: [
                        {
                            text: 'Perawatan',
                        },
                        {
                            text: 'Notifikasi & Pengingat',
                            active: true,
                        },
                    ],
                },
            },
        ]
    },
]
