import store from '@/store'
export default [
  {
    path: '/spb',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'spb',
        component: () => import('@/views/pages/order-unit/spb/Index.vue'),
        meta: {
          user: store.state.profile.info,
          pageTitle: 'Daftar SPB',
          pageActive: 'spb',
          breadcrumb: [
            {
              text: 'Pemesanan Unit',
            },
            {
              text: 'Semua Daftar SPB',
              active: true,
            },
          ],
        },
      },
      
      {
        path: 'create',
        name: 'spb-create',
        component: () => import('@/views/pages/order-unit/spb/Form.vue'),
        meta:{
          navActiveLink: 'spb',
          pageActive: 'spb',
          pageTitle: 'Daftar SPB',
          breadcrumb: [
            {
              text: 'Pemesanan Unit',
            },
            {
              text: 'SPB',
              to: '../spb',
            },
            {
              text: 'Add',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'spb-edit',
        component: () => import('@/views/pages/order-unit/spb/Form.vue'),
        meta:{
          navActiveLink: 'spb',
          pageTitle: 'Daftar SPB',
          pageActive: 'spb-detail',
          breadcrumb: [
            {
              text: 'Pemesanan Unit',
            },
            {
              text: 'SPB',
              to: '../spb',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'detail/:id',
        name: 'spb-detail',
        component: () => import('@/views/pages/order-unit/spb/View.vue'),
        meta:{
          navActiveLink: 'spb',
          pageTitle: 'Daftar SPB',
          pageActive: 'spb',
          breadcrumb: [
            {
              text: 'Daftar SPB',
              to: '/spb',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
      {
        path: '/spb-vendor',
        component: () => import('@/layouts/full/LayoutFull.vue'),
        children:[
          {
            path: '',
            name: 'spb-vendor',
            component: () => import('@/views/pages_vendor/order-unit/spb/Index.vue'),
            meta: {
              user: store.state.profile.info,
              pageTitle: 'Daftar SPB',
              pageActive: 'spb-vendor',
              breadcrumb: [
                {
                  text: 'Pemesanan Unit',
                },
                {
                  text: 'Semua Daftar SPB',
                  active: true,
                },
              ],
            },
          },
          {
            path: 'detail/:id',
            name: 'spb-vendor-detail',
            component: () => import('@/views/pages/order-unit/spb/View.vue'),
            meta:{
              navActiveLink: 'spb-vendor',
              pageTitle: 'Detail SPB',
              pageActive: 'spb-vendor',
              breadcrumb: [
                {
                  text: 'Daftar Semua SPB',
                  to: '/spb-vendor',
                },
                {
                  text: 'Detail',
                  active: true,
                },
              ],
            }
          },
        ]
      },
  {
    path: '/pemeriksaan',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'pemeriksaan',
        component: () => import('@/views/pages/order-unit/pemeriksaan/Index.vue'),
        meta: {
          pageTitle: 'Daftar Pemeriksaan',
          pageActive: 'pemeriksaan',
          navActiveLink : 'pemeriksaan',
          breadcrumb: [
            {
              text: 'Pemeriksaan',
            },
            {
              text: 'Semua Daftar Pemeriksaan',
              active: true,
            },
          ],
        },
      },
      {
        path: 'detail/:id',
        name: 'pemeriksaan-detail',
        component: () => import('@/views/pages/order-unit/spb/View.vue'),
        meta:{
          navActiveLink: 'pemeriksaan',
          pageTitle: 'Detail Pemeriksaan',
          pageActive: 'pemeriksaan',
          breadcrumb: [
            {
              text: 'Daftar Pemeriksaan',
              to: '/pemeriksaan',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/penerimaan',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'penerimaan',
        component: () => import('@/views/pages/order-unit/penerimaan/Index.vue'),
        meta: {
          pageTitle: 'Daftar Penerimaan',
          pageActive: 'penerimaan',
          breadcrumb: [
            {
              text: 'Penerimaan',
            },
            {
              text: 'Semua Daftar Penerimaan',
              active: true,
            },
          ],
        },
      },
      {
        path: 'detail/:id',
        name: 'penerimaan-detail',
        component: () => import('@/views/pages/order-unit/spb/View.vue'),
        meta:{
          navActiveLink: 'penerimaan',
          pageTitle: 'Detail Penerimaan',
          pageActive: 'penerimaan',
          breadcrumb: [
            {
              text: 'Daftar Penerimaan',
              to: '/penerimaan',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/persiapan-unit',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'persiapan-unit',
        component: () => import('@/views/pages/order-unit/persiapan/Index.vue'),
        meta: {
          pageTitle: 'Daftar Persiapan Unit',
          pageActive: 'persiapan-unit',
          breadcrumb: [
            {
              text: 'Persiapan Unit',
            },
            {
              text: 'Semua Daftar Persiapan Unit',
              active: true,
            },
          ],
        },
      },
      {
        path: 'detail/:id',
        name: 'persiapan-unit-detail',
        component: () => import('@/views/pages/order-unit/spb/View.vue'),
        meta:{
          navActiveLink: 'persiapan-unit',
          pageTitle: 'Detail Persiapan Unit',
          pageActive: 'persiapan-unit',
          breadcrumb: [
            {
              text: 'Daftar Persiapan Unit',
              to: '/persiapan-unit',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/persiapan-unit-vendor',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'persiapan-unit-vendor',
        component: () => import('@/views/pages_vendor/order-unit/persiapan/Index.vue'),
        meta: {
          user: store.state.profile.info,
          pageTitle: 'Daftar Persiapan Unit',
          pageActive: 'persiapan-unit-vendor',
          breadcrumb: [
            {
              text: 'Persiapan Unit',
            },
            {
              text: 'Semua Daftar Persiapan Unit',
              active: true,
            },
          ],
        },
      },
      {
        path: '/persiapan-unit-vendor-show/:id',
        name: 'persiapan-unit-vendor-show',
        component: () => import('@/views/pages_vendor/order-unit/persiapan/IndexShow.vue'),
        meta:{
          navActiveLink: 'persiapan-unit-vendor',
          pageTitle: 'Daftar SPB Persiapan Unit',
          pageActive: 'persiapan-unit-vendor',
          breadcrumb: [
            {
              text: 'Daftar SPB Persiapan Unit',
              to: '/persiapan-unit-vendor',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
      {
        // path: '/persiapan-unit-vendor-detail/:id/vehicle/:id_vehicle/:variant_id/:vh_id',
        path: '/persiapan-unit-vendor-detail/:id/vehicle/:id_vehicle/:variant_id',
        name: 'persiapan-unit-vendor-detail',
        component: () => import('@/views/pages_vendor/order-unit/persiapan/Show.vue'),
        meta:{
          navActiveLink: 'persiapan-unit-vendor',
          pageTitle: 'Detail SPB Persiapan Unit',
          pageActive: 'persiapan-unit-vendor',
          breadcrumb: [
            {
              text: 'Detail SPB Persiapan Unit',
              to: '/persiapan-unit-vendor'
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
      {
        path: '/persiapan-unit-varian-edit/:id',
        name: 'persiapan-unit-varian-edit',
        component: () => import('@/views/pages_vendor/order-unit/persiapan/include/FormSPB.vue'),
        meta:{
          navActiveLink: 'persiapan-unit-vendor',
          pageTitle: 'Detail SPB Persiapan Unit',
          pageActive: 'persiapan-unit-vendor',
          breadcrumb: [
            {
              text: 'Detail SPB Persiapan Unit',
              to: '/persiapan-unit-vendor'
            },
            {
              text: 'Detail',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/distribute',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'distribute',
        component: () => import('@/views/pages/order-unit/distribute/Index.vue'),
        meta: {
          pageTitle: 'Daftar Distribusi',
          pageActive: 'distribute',
          breadcrumb: [
            {
              text: 'Distribusi',
            },
            {
              text: 'Semua Daftar Distribusi',
              active: true,
            },
          ],
        },
      },
      {
        path: 'detail/:id',
        name: 'distribute-detail',
        component: () => import('@/views/pages/order-unit/spb/View.vue'),
        meta:{
          navActiveLink: 'distribute',
          pageTitle: 'Detail Distribusi',
          pageActive: 'distribute',
          breadcrumb: [
            {
              text: 'Daftar Distribusi',
              to: '/distribute',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/serah-terima',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'serah-terima',
        component: () => import('@/views/pages/order-unit/serah-terima/Index.vue'),
        meta: {
          pageTitle: 'Daftar Serah Terima',
          pageActive: 'serah-terima',
          breadcrumb: [
            {
              text: 'Pemesanan Unit',
            },
            {
              text: 'Semua Serah Terima',
              active: true,
            },
          ],
        },
      },
      {
        path: 'detail/:id',
        name: 'serah-terima-detail',
        component: () => import('@/views/pages/order-unit/serah-terima/View.vue'),
        meta:{
          navActiveLink: 'serah-terima',
          pageTitle: 'Detail Serah Terima',
          pageActive: 'serah-terima',
          breadcrumb: [
            {
              text: 'Daftar Serah Terima',
              to: '/serah-terima',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/serah-terima-vendor',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'serah-terima-vendor',
        component: () => import('@/views/pages_vendor/order-unit/serah-terima/Index.vue'),
        meta: {
          user: store.state.profile.info,
          pageTitle: 'Daftar Serah Terima',
          pageActive: 'serah-terima-vendor',
          breadcrumb: [
            {
              text: 'Pemesanan Unit',
            },
            {
              text: 'Semua Serah Terima',
              active: true,
            },
          ],
        },
      },
      {
        path: 'detail/:id',
        name: 'serah-terima-vendor-detail',
        component: () => import('@/views/pages_vendor/order-unit/serah-terima/View.vue'),
        meta:{
          user: store.state.profile.info,
          navActiveLink: 'serah-terima-vendor',
          pageTitle: 'Detail Serah Terima',
          pageActive: 'serah-terima-vendor',
          breadcrumb: [
            {
              text: 'Daftar Serah Terima',
              to: '/serah-terima-vendor',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
]