import store from '@/store'
export default [
  {
    path: '/company',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vendor-company',
        component: () => import('@/views/pages/contact/vendor/Show.vue'),
        meta:{
          user: store.state.profile.info,
          baseroute: 'vendor-company',
          pageTitle: 'Data Perusahaan',
          breadcrumb: [
            {
              text: 'Detail',
            },
            // {
            //   text: 'Daftar Asuransi',
            //   active: true,
            // },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'vendor-company-edit',
        component: () => import('@/views/pages/contact/vendor/Form.vue'),
        meta:{
          user: store.state.profile.info,
          baseroute: 'vendor-company-edit',
          pageTitle: 'Data Perusahaan',
          breadcrumb: [
            {
              text: 'Data Perusahaan',
              to:'..'
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: 'v-assurance',
        name: 'vendor-assurance',
        component: () => import('@/views/pages/contact/vendor/include/IndexAsurance.vue'),
        meta:{
          user: store.state.profile.info,
          baseroute: 'vendor-assurance',
          filterIndex: true,
          isDelete:false,
          titleIndex: 'Daftar Asuransi',
          pageTitle: 'Asuransi',
          breadcrumb: [
            {
              text: 'Daftar Asuransi',
              active: true,
            },
          ],
        }
      },
      {
        path: 'v-assurance/show/:id',
        name: 'vendor-assurance-view',
        component: () => import('@/views/pages/contact/assurance/Show.vue'),
        meta:{
          navActiveLink: 'vendor-assurance',
          pageTitle: 'Asuransi Detail',
          breadcrumb: [
            {
              text: 'Asuransi Rekanan',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: 'v-workshop',
        name: 'vendor-workshop',
        component: () => import('@/views/pages/contact/vendor/include/IndexWorkshop.vue'),
        meta:{
          user: store.state.profile.info,
          baseroute: 'vendor-workshop',
          filterIndex: true,
          isDelete:false,
          titleIndex: 'Daftar Bengkel',
          pageTitle: 'Bengkel Rekanan',
          breadcrumb: [
            {
              text: 'Daftar Bengkel',
              active: true,
            },
          ],
        }
      },
      {
        path: 'v-workshop/show/:id',
        name: 'vendor-workshop-view',
        component: () => import('@/views/pages/contact/workshop/Show.vue'),
        meta:{
          navActiveLink: 'vendor-workshop',
          pageTitle: 'Bengkel Detail',
          breadcrumb: [
            {
              text: 'Bengkel Rekanan',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/v-vehicle-change',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'v-vehicle-change',
        component: () => import('@/views/pages/vehicle/change_unit/Index.vue'),
        meta:{
          user: store.state.profile.info,
          titleIndex: 'Daftar Penggantian Unit',
          baseroute: 'v-vehicle-change',
          pageTitle: 'Penggantian Unit',
          breadcrumb: [
            {
              text: 'Daftar Penggantian Unit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'v-vehicle-change-create',
        component: () => import('@/views/pages/vehicle/change_unit/Form.vue'),
        meta:{
          user: store.state.profile.info,
          navActiveLink: 'v-vehicle-change',
          baseroute: 'v-vehicle-change',
          pageTitle: 'Penggantian Unit',
          breadcrumb: [
            {
              text: 'Penggantian Unit',
              to: '../v-vehicle-change',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'v-vehicle-change-view',
        component: () => import('@/views/pages/vehicle/change_unit/Show.vue'),
        meta:{
          user: store.state.profile.info,
          navActiveLink: 'v-vehicle-change',
          baseroute: 'v-vehicle-change',
          pageTitle: 'Penggantian Unit Detail',
          breadcrumb: [
            {
              text: 'Penggantian Unit',
              to: '..',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'v-vehicle-change-edit',
        component: () => import('@/views/pages/vehicle/change_unit/Form.vue'),
        meta:{
          navActiveLink: 'v-vehicle-change',
          pageTitle: 'Penggantian Unit',
          breadcrumb: [
            {
              text: 'Penggantian Unit',
              to: '..',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/v-vehicle-getback',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vehicle-getback',
        component: () => import('@/views/pages/vehicle/getback_unit/Index.vue'),
        meta:{
          user: store.state.profile.info,
          baseroute: 'vehicle-getback',
          filterIndex: true,
          titleIndex: 'Daftar Pengambilan Unit',
          pageTitle: 'Pengambilan Unit',
          breadcrumb: [
            {
              text: 'Daftar Pengambilan Unit',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'vehicle-getback-view',
        component: () => import('@/views/pages/vehicle/getback_unit/Show.vue'),
        meta:{
          navActiveLink: 'vehicle-getback',
          pageTitle: 'Pengambilan Unit Detail',
          breadcrumb: [
            {
              text: 'Pengambilan Unit',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/v-vehicle-claim',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'vehicle-claim',
        component: () => import('@/views/pages/vehicle/claim/Index.vue'),
        meta:{
          user: store.state.profile.info,
          baseroute: 'vehicle-claim',
          filterIndex: true,
          titleIndex: 'Klaim Kendaraan',
          pageTitle: 'Klaim Kendaraan',
          breadcrumb: [
            {
              text: 'Daftar Klaim',
              active: true,
            },
          ],
        }
      },
      {
        path: 'create',
        name: 'vehicle-claim-create',
        component: () => import('@/views/pages/vehicle/claim/Form.vue'),
        meta:{
          user: store.state.profile.info,
          navActiveLink: 'vehicle-claim',
          baseroute: 'vehicle-claim',
          pageTitle: 'Klaim Kendaraan',
          breadcrumb: [
            {
              text: 'Klaim Kendaraan',
              to: '../v-vehicle-claim',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'vehicle-claim-view',
        component: () => import('@/views/pages/vehicle/claim/Show.vue'),
        meta:{
          navActiveLink: 'vehicle-claim',
          pageTitle: 'Klaim Kendaraan',
          breadcrumb: [
            {
              text: 'Klaim Kendaraan',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
      {
        path: 'edit/:id',
        name: 'vehicle-claim-edit',
        component: () => import('@/views/pages/vehicle/claim/Form.vue'),
        meta:{
          user: store.state.profile.info,
          navActiveLink: 'vehicle-claim',
          pageTitle: 'Klaim Kendaraan',
          breadcrumb: [
            {
              text: 'Klaim Kendaraan',
              to: '..',
            },
            {
              text: 'Edit',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/v-tax',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'v-tax',
        component: () => import('@/views/pages/operasional/taxes/Index.vue'),
        meta: {
          user: store.state.profile.info,
          pageTitle: 'Pajak & Kir',
          breadcrumb: [
            {
              text: 'Kendaraan',
            },
            {
              text: 'Daftar Pajak & Kir',
              active: true,
            }
          ]
        }
      },
    ]
  },
  {
    path: '/v-billing-rent',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'v-billing-rent',
        component: () => import('@/views/pages/billing/rent/Index.vue'),
        meta:{
          user: store.state.profile.info,
          baseroute: 'v-billing-rent',
          filterIndex: true,
          titleIndex: 'Daftar Penagihan Sewa',
          pageTitle: 'Penagihan Sewa',
          breadcrumb: [
            {
              text: 'Daftar Penagihan Sewa',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'v-billing-rent-view',
        component: () => import('@/views/pages/billing/rent/Show.vue'),
        meta:{
          user: store.state.profile.info,
          baseroute: 'v-billing-rent',
          navActiveLink: 'v-billing-rent',
          pageTitle: 'Penagihan Sewa Detail',
          breadcrumb: [
            {
              text: 'Penagihan Sewa',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id/maintenance/:vehicle_id',
        name: 'v-billing-rent-maintenance',
        component: () => import('@/views/pages/billing/rent/include/IndexMaintenance.vue'),
        meta:{
          baseroute: 'v-billing-rent',
          navActiveLink: 'v-billing-rent',
          pageTitle: 'Detail Perawatan',
          breadcrumb: [
            {
              text: 'Penagihan Sewa',
              to: '..',
            },
            {
              text: 'Detail Perawatan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id/upload',
        name: 'v-billing-rent-upload',
        component: () => import('@/views/pages/billing/rent/include/FormUpload.vue'),
        meta:{
          navActiveLink: 'billing-rent',
          pageTitle: 'Penagihan Sewa',
          breadcrumb: [
            {
              text: 'Penagihan Sewa',
              to: '.',
            },
            {
              text: 'Upload Berkas',
              active: true,
            },
          ],
        }
      },
    ]
  },
  {
    path: '/penagihan',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: 'own-risk',
        name: 'own-risk',
        component: () => import('@/views/pages_vendor/penagihan/risk/Index.vue'),
        meta:{
          user: store.state.profile.info,
          navActiveLink: 'own-risk',
          pageActive: 'own-risk',
          pageTitle: 'Resiko Pribadi',
          breadcrumb: [
            {
              text: 'Penagihan',
            },
            {
              text: 'Daftar Resiko Pribadi',
              active: true,
            },
          ],
        },
      },
      {
        path: '/own-risk-view/:id',
        name: 'own-risk-view',
        component: () => import('@/views/pages_vendor/penagihan/risk/Detail.vue'),
        meta:{
          navActiveLink: 'own-risk',
          pageActive: 'own-risk',
          pageTitle: 'Detail Resiko Pribadi',
          breadcrumb: [
            {
              text: 'Penagihan',
            },
            {
              text: 'Daftar Resiko Pribadi',
              to: 'own-risk',
            },
            {
              text: 'Detail',
              active: true,
            }
          ],
        },
      },
    ]
  }
]