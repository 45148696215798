export default [
  {
    path: '/billing-rent',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'billing-rent',
        component: () => import('@/views/pages/billing/rent/Index.vue'),
        meta:{
          pageTitle: 'Penagihan Sewa',
          breadcrumb: [
            {
              text: 'Daftar Penagihan Sewa',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id',
        name: 'billing-rent-view',
        component: () => import('@/views/pages/billing/rent/Show.vue'),
        meta:{
          navActiveLink: 'billing-rent',
          pageTitle: 'Penagihan Sewa Detail',
          breadcrumb: [
            {
              text: 'Penagihan Sewa',
              to: '../',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id/maintenance/:vehicle_id',
        name: 'billing-rent-maintenance',
        component: () => import('@/views/pages/billing/rent/include/IndexMaintenance.vue'),
        meta:{
          navActiveLink: 'billing-rent',
          pageTitle: 'Detail Perawatan',
          breadcrumb: [
            {
              text: 'Penagihan Sewa',
              to: '..',
            },
            {
              text: 'Detail Perawatan',
              active: true,
            },
          ],
        }
      },
      {
        path: 'show/:id/upload',
        name: 'billing-rent-upload',
        component: () => import('@/views/pages/billing/rent/include/FormUpload.vue'),
        meta:{
          navActiveLink: 'billing-rent',
          pageTitle: 'Penagihan Sewa',
          breadcrumb: [
            {
              text: 'Penagihan Sewa',
              to: '.',
            },
            {
              text: 'Upload Berkas',
              active: true,
            },
          ],
        }
      },
      
    ]
  },
  {
    path: '/own-risk',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'internal-own-risk',
        component: () => import('@/views/pages/billing/risk/Index.vue'),
        meta:{
          navActiveLink: 'internal-own-risk',
          pageActive: 'internal-own-risk',
          pageTitle: 'Resiko Pribadi',
          breadcrumb: [
            {
              text: 'Penagihan',
            },
            {
              text: 'Daftar Resiko Pribadi',
              active: true,
            },
          ],
        },
      },
      {
        path: '/own-risk-view/:id',
        name: 'own-risk-view',
        component: () => import('@/views/pages/billing/risk/Detail.vue'),
        meta:{
          navActiveLink: 'own-risk',
          pageActive: 'own-risk',
          pageTitle: 'Detail Resiko Pribadi',
          breadcrumb: [
            {
              text: 'Penagihan',
            },
            {
              text: 'Daftar Resiko Pribadi',
              to: '../own-risk',
            },
            {
              text: 'Detail',
              active: true,
            }
          ],
        },
      },
    ]
  },
  {
    path: '/fuel-cost',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'fuel-cost',
        component: () => import('@/views/pages/finance/fuel-cost/Index.vue'),
        meta: {
          pageTitle: 'Biaya BBM',
          breadcrumb: [
            {
              text: 'Keuangan',
            },
            {
              text: 'Biaya BBM',
              active: true,
            },
          ],
        },
      },
    ]
  },
  {
    path: '/finance-checklist',
    component: () => import('@/layouts/full/LayoutFull.vue'),
    children:[
      {
        path: '',
        name: 'finance-checklist',
        component: () => import('@/views/pages/finance/checklist/Index.vue'),
        meta: {
          pageTitle: 'Checklist Tagihan',
          breadcrumb: [
            {
              text: 'Keuangan',
            },
            {
              text: 'Checklist',
              active: true,
            },
          ],
        },
      },
      {
        path: 'create',
        name: 'finance-checklist-create',
        component: () => import('@/views/pages/finance/checklist/Form.vue'),
        meta:{
          navActiveLink: 'finance-checklist',
          pageTitle: 'Checklist Tagihan',
          breadcrumb: [
            {
              text: 'Keuangan',
            },
            {
              text: 'Checklist Tagihan',
              to: '../finance-checklist',
            },
            {
              text: 'Tambah',
              active: true,
            },
          ],
        }
      },
      {
        path: 'view/:id',
        name: 'finance-checklist-view',
        component: () => import('@/views/pages/finance/checklist/Show.vue'),
        meta:{
          navActiveLink: 'finance-checklist',
          pageActive: 'finance-checklist',
          pageTitle: 'Detail Checklist',
          breadcrumb: [
            {
              text: 'Keuangan',
            },
            {
              text: 'Daftar Checklist',
              to: '..',
            },
            {
              text: 'Detail',
              active: true,
            }
          ],
        },
      },
    ]
  },
]