import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import profile from './profile'
import notification from './notification'
import websocket from './websocket'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    profile,
    notification,
    websocket
  },
  mutations: {
    SOCKET_CONNECT: (state, status) => {
      console.info("connected");
    },
    SOCKET_DISCONNECT: (state, status) => {
      console.info("disconnected");
    },
  },
  strict: process.env.DEV,
})
