export default {
  namespaced: true,
  state: {
    info: JSON.parse(localStorage.getItem('userData')) || { name: 'hi' }
  },
  getters: {},
  mutations: {
    UPDATE_PROFILE(state, val) {
      console.log("UPDATE_PROFILE")
      state.info = val
    },
  },
  actions: {},
}
